import React from 'react';
import MenuHeader from '../common/menuHeader';
import MenuLink from '../common/menuLink';
import * as MENU from '../../constants/menu';
import * as ICON from '../../constants/icon';
import * as ROUTE from '../../constants/route';
import * as MODULE from '../../constants/module';
import { isAdminTenant } from '../../utils/tenant';
import useAuth from '../../hooks/useAuth';

const FuelCardsMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  return !isAdminTenant() ? (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.FUEL_CARDS} icon={ICON.CREDIT_CARD} />
      <div className="dropdown-menu">
        <MenuLink
          label={MENU.MOL_LOADER}
          url={ROUTE.MOL_LOADER}
          module={MODULE.MOL}
          user={user}
        />
        <MenuLink
          label={MENU.EUROWAG_LOADER}
          url={ROUTE.EUROWAG_LOADER}
          module={MODULE.EUROWAG}
          user={user}
        />
        <MenuLink
          label={MENU.EUROWAG_2021_LOADER}
          url={ROUTE.EUROWAG_2021_LOADER}
          module={MODULE.EUROWAG}
          user={user}
        />
        <MenuLink
          label={MENU.EUROWAG_CSV_LOADER}
          url={ROUTE.EUROWAG_CSV_LOADER}
          module={MODULE.EUROWAG}
          user={user}
        />
        <MenuLink
          label={MENU.EUROWAG_BONUS_LOADER}
          url={ROUTE.EUROWAG_BONUS_LOADER}
          module={MODULE.EUROWAG}
          user={user}
        />
        <MenuLink
          label={MENU.EUROWAG_MASTER_CARD_LOADER}
          url={ROUTE.EUROWAG_MASTER_CARD_LOADER}
          module={MODULE.EUROWAG}
          user={user}
        />
        <MenuLink
          label={MENU.EUROWAG_TOLL_GB_SOURCE_LOADER}
          url={ROUTE.EUROWAG_TOLL_GB_SOURCE_LOADER}
          module={MODULE.EUROWAG}
          user={user}
        />
        <MenuLink
          label={MENU.EUROWAG_TOLL_CH_SOURCE_LOADER}
          url={ROUTE.EUROWAG_TOLL_CH_SOURCE_LOADER}
          module={MODULE.EUROWAG}
          user={user}
        />
        <MenuLink
          label={MENU.SHELL_LOADER}
          url={ROUTE.SHELL_LOADER}
          module={MODULE.SHELL}
          user={user}
        />
        <MenuLink
          label={MENU.SHELL_ORIGINAL_LOADER}
          url={ROUTE.SHELL_ORIGINAL_LOADER}
          module={MODULE.SHELL_ORIGINAL}
          user={user}
        />
        <MenuLink
          label={MENU.SHELL_ORIGINAL2_LOADER}
          url={ROUTE.SHELL_ORIGINAL2_LOADER}
          module={MODULE.SHELL_ORIGINAL}
          user={user}
        />
        <MenuLink
          label={MENU.OMV_LOADER}
          url={ROUTE.OMV_LOADER}
          module={MODULE.OMV}
          user={user}
        />
        <MenuLink
          label={MENU.DKV_LOADER}
          url={ROUTE.DKV_LOADER}
          module={MODULE.DKV}
          user={user}
        />
        <MenuLink
          label={MENU.OIL_LOADER}
          url={ROUTE.OIL_LOADER}
          module={MODULE.OIL}
          user={user}
        />
        <MenuLink
          label={MENU.MOBIL_PETROL_LOADER}
          url={ROUTE.MOBIL_PETROL_LOADER}
          module={MODULE.MOBILPETROL}
          user={user}
        />
        <MenuLink
          label={MENU.FLEETCOR_LOADER}
          url={ROUTE.FLEETCOR_LOADER}
          module={MODULE.FLEETCOR}
          user={user}
        />
        <MenuLink
          label={MENU.MOTO95_LOADER}
          url={ROUTE.MOTO95_LOADER}
          module={MODULE.MOTO95}
          user={user}
        />
        <MenuLink
          label={MENU.ARIS_LOADER}
          url={ROUTE.ARIS_LOADER}
          module={MODULE.ARIS}
          user={user}
        />
        <MenuLink
          label={MENU.AS24_LOADER}
          url={ROUTE.AS24_LOADER}
          module={MODULE.AS24}
          user={user}
        />
        <MenuLink
          label={MENU.AS24_EUR_LOADER}
          url={ROUTE.AS24_EUR_LOADER}
          module={MODULE.AS24}
          user={user}
        />
        <MenuLink
          label={MENU.AS24_NEW_LOADER}
          url={ROUTE.AS24_NEW_LOADER}
          module={MODULE.AS24}
          user={user}
        />
        <MenuLink
          label={MENU.LUKOIL_LOADER}
          url={ROUTE.LUKOIL_LOADER}
          module={MODULE.LUKOIL}
          user={user}
        />
        <MenuLink
          label={MENU.OPLUS_LOADER}
          url={ROUTE.OPLUS_LOADER}
          module={MODULE.OPLUS}
          user={user}
        />
        <MenuLink
          label={MENU.WSZL_LOADER}
          url={ROUTE.WSZL_LOADER}
          module={MODULE.WSZL}
          user={user}
        />
        <MenuLink
          label={MENU.WSZL_NEW_LOADER}
          url={ROUTE.WSZL_NEW_LOADER}
          module={MODULE.WSZL}
          user={user}
        />
        <MenuLink
          label={MENU.IMIX_LOADER}
          url={ROUTE.IMIX_LOADER}
          module={MODULE.IMIX}
          user={user}
        />
        <MenuLink
          label={MENU.VARGA_ES_TARSA_LOADER}
          url={ROUTE.VARGA_ES_TARSA_LOADER}
          module={MODULE.VARGA_ES_TARSA}
          user={user}
        />
        <MenuLink
          label={MENU.EDO_LOADER}
          url={ROUTE.EDO_LOADER}
          module={MODULE.EDO}
          user={user}
        />
        <MenuLink
          label={MENU.FLEETCOR_2024_LOADER}
          url={ROUTE.FLEETCOR_2024_LOADER}
          module={MODULE.FLEETCOR}
          user={user}
        />
        <MenuLink
          label={MENU.JOGA_LOADER}
          url={ROUTE.JOGA_LOADER}
          module={MODULE.JOGA}
          user={user}
        />
        <MenuLink
          label={MENU.TRANSDANUBIA_LOADER}
          url={ROUTE.TRANSDANUBIA_LOADER}
          module={MODULE.TRANSDANUBIA}
          user={user}
        />
        <MenuLink
          label={MENU.DUVENBECK_LOADER}
          url={ROUTE.DUVENBECK_LOADER}
          module={MODULE.DUVENBECK}
          user={user}
        />
        <MenuLink
          label={MENU.UTA_LOADER}
          url={ROUTE.UTA_LOADER}
          module={MODULE.UTA}
          user={user}
        />
        <MenuLink
          label={MENU.UTA_NORBI_LOADER}
          url={ROUTE.UTA_NORBI_LOADER}
          module={MODULE.UTA}
          user={user}
        />
      </div>
    </div>
  ) : null;
};

export default FuelCardsMenu;
